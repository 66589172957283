// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["WeGDYettA"];

const serializationHash = "framer-8cWdU"

const variantClassNames = {WeGDYettA: "framer-v-8hz9pi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, CuzRFOk6M: link ?? props.CuzRFOk6M} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CuzRFOk6M, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "WeGDYettA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={CuzRFOk6M} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8hz9pi", className, classNames)} framer-1kyqxj4`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WeGDYettA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(255, 101, 53))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}><ComponentViewportProvider ><motion.div className={"framer-ynsew6-container"} layoutDependency={layoutDependency} layoutId={"A8teWMcvn-container"}><Phosphor color={"var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(255, 101, 53))"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowRight"} id={"A8teWMcvn"} layoutId={"A8teWMcvn"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8cWdU.framer-1kyqxj4, .framer-8cWdU .framer-1kyqxj4 { display: block; }", ".framer-8cWdU.framer-8hz9pi { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: 48px; justify-content: center; overflow: hidden; padding: 12px 16px 12px 18px; position: relative; text-decoration: none; width: 48px; will-change: var(--framer-will-change-override, transform); }", ".framer-8cWdU .framer-ynsew6-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8cWdU.framer-8hz9pi { gap: 0px; } .framer-8cWdU.framer-8hz9pi > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-8cWdU.framer-8hz9pi > :first-child { margin-left: 0px; } .framer-8cWdU.framer-8hz9pi > :last-child { margin-right: 0px; } }", ".framer-8cWdU[data-border=\"true\"]::after, .framer-8cWdU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CuzRFOk6M":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerD8VnNLmhN: React.ComponentType<Props> = withCSS(Component, css, "framer-8cWdU") as typeof Component;
export default FramerD8VnNLmhN;

FramerD8VnNLmhN.displayName = "Button Circle Arrow";

FramerD8VnNLmhN.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerD8VnNLmhN, {CuzRFOk6M: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerD8VnNLmhN, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})